import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import "./Header.styles.scss";
import rightTick from "../../../../assets/images/righttick.svg";
import fatcatHome from "../../../../assets/images/fatcat_home.png";
import bannerImg from "../../../../assets/images/home-banner.png";
import { handleEmailSubmission } from "../../../../utils/formUtils";
function Header() {
  const [windowDimension, setWindowDimension] = useState(null);
  const [email, setEmail] = useState("null");

  useEffect(() => {
    setWindowDimension(window.innerWidth);
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function handleClick(e) {
    e.showBulkActions = true;
    setTimeout(() => {
      e.showBulkActions = false;
      console.log(e.showBulkActions);
    }, 5000);
    console.log(e.showBulkActions);
  }
  const handleEmailChange = (evt) => {
    setEmail(evt.target.value);
  };
  const handleFormSubmission = (evt) => {
    evt.preventDefault();
    // handleEmailSubmission
    handleEmailSubmission(email);
  };
  const isMobile = windowDimension <= 640;
  return (
    <div className="header-container home-head">
      {/* <Navbar isMobile={isMobile} /> */}
      <div class="container home-banner-fix">
        <div className="header-content wow fadeInUp">
          <span className="header-content-title">
            Bring Technology to Your Campus
          </span>
          <span className="header-content-desc">
            Harness the power of data to transform educational institutions,
            enhance teaching methodologies, and drive student success. Discover
            how Rubrix innovative platform is reshaping the future of education
            through actionable insights and cutting-edge technology.
          </span>
          <span className="header-content-input" onClick={handleClick}>
            <input
              type="email"
              placeholder="Email address"
              onChange={handleEmailChange}
            />
            <span onClick={handleFormSubmission}>
              Sign Up - It's Free
              <img src={rightTick} alt="right" />
            </span>
          </span>
          {isMobile && (
            <div className="fatcat-mob-head">
              <img src={bannerImg} alt="fatcat-mob" />
            </div>
          )}
        </div>
        <div class="rightImage desktop-only  wow fadeInRight">
          <img src={bannerImg} alt="banner" />
        </div>
      </div>
    </div>
  );
}

export default Header;
