// /* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import "./Navbar.styles.scss";
import Fatcat_Logo_ from "../../../../assets/images/Fatcat_Logo_.png";
import Collapse, { Panel } from "rc-collapse";
import "rc-collapse/assets/index.css";
import accordArrow from "../../../../assets/images/accordarrow.svg";
import accordArrowUP from "../../../../assets/images/accordarrowup.svg";
import dropdownArr from "../../../../assets/images/dropdown-arr.svg";
import { Link } from "react-router-dom";
export default function Navbar({ affix }) {
  const stickyScrollHandler = () => {
    if (window.scrollY > 50) {
      document.querySelector(".siteHeader").classList.add("affix");
      return;
    }
    document.querySelector(".siteHeader").classList.remove("affix");
  };
  const menuToggle = (e) => {
    document.getElementsByTagName("body")[0].classList.toggle("menu-active");
    document
      .getElementsByClassName("hamburger--squeeze")[0]
      .classList.toggle("is-active");
  };
  useEffect(() => {
    window.addEventListener("scroll", stickyScrollHandler);
    return () => {
      window.removeEventListener("scroll", stickyScrollHandler);
    };
  }, []);
  return (
    <header id="header" className="siteHeader">
      <div className="container">
        <div className="row">
          <div className="branding">
            <a href="/">
              <img src={Fatcat_Logo_} alt="FatCat" />
            </a>
            <span className="mob-nav-buttons">
              <a className="mob-ask-for-demo menuToggle" href="/ask-us-demo">
                Sign Up - It's Free
              </a>
              <button
                class="hamburger hamburger--squeeze"
                type="button"
                onClick={menuToggle}
              >
                <span class="hamburger-box">
                  <span class="hamburger-inner"></span>
                </span>
              </button>
            </span>
          </div>
          <nav className="mainNav">
            <ul className="d-flex">
              {/* <li className="dropdown-submenu">
                <a href="/academy">
                  Services
                  <img src={dropdownArr} alt="dropdown" />
                </a>
                <ul className="sub-menu">
                  <li>
                    <Link to="/academy">For Institutes</Link>
                  </li>
                  <li>
                    <Link to="/students">For Students</Link>
                  </li>
                  <li>
                    <Link to="/staff">For Educators</Link>
                  </li>
                </ul>
              </li> */}
              <li className="navlink-mobile-only">
                <Collapse
                  expandIcon={({ isActive }) => (
                    <img
                      src={isActive ? accordArrowUP : accordArrow}
                      alt="arrow"
                    />
                  )}
                  accordion={true}
                  className="navlink-dropdown"
                >
                  {/* <Panel
                    key="1"
                    header="Services"
                    headerClass="navlink-dropdown-header"
                  >
                    <ul className="expanded-links">
                      <li className="navlink-item">
                        <Link to="/academy">For Institutes</Link>
                      </li>
                      <li className="navlink-item">
                        <Link to="/students">For Students</Link>
                      </li>
                      <li className="navlink-item">
                        <Link to="/staff">For Educators</Link>
                      </li>
                    </ul>
                  </Panel> */}
                </Collapse>
              </li>
              {/* <li>
                <a href="/features">Features</a>
              </li> */}
              {/* <li>
                <a href="/company">Company</a>
              </li>
              <li>
                <a href="/blog">Blog</a>
              </li> */}
              <li className="login-btn">
                <a href="http://webapp.rubrix.ai/login" target="_blank">
                  Login
                </a>
              </li>
              <li>
                <a href="/ask-us-demo"> Sign Up - It's Free</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}
